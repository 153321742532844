import * as React from "react";
import Posts from "./posts";
import Category from "./category";
import styled from "styled-components";

const SidebarRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-41xl);
  text-align: left;
  font-size: var(--h3a-size);
  color: var(--color-darkslategray-200);
  font-family: var(--h3a);
`;

const SIDEBAR = () => {
  return (
    <SidebarRoot>
      <Posts />
      {/* <Category /> */}
    </SidebarRoot>
  );
};

export default SIDEBAR;
