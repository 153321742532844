import * as React from "react";
import { Link } from 'gatsby';

import styled from "styled-components";
import SidebarPosts from "./sidebar-posts";

const PopularPosts = styled.b`
  position: relative;
  line-height: 120%;
`;
const TitleChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0.188rem;
  flex-shrink: 0;
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const PostsRoot = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--h3a-size);
  color: var(--color-darkslategray-200);
  font-family: var(--h3a);
`;

const Posts = () => {
  return (
    <PostsRoot>
      <Title>
        <PopularPosts>Popular posts</PopularPosts>
        <TitleChild alt="" src="/line-6.svg" />
      </Title>
      <Link to="/pitfalls">
      <SidebarPosts
        postImage="/post-image@2x.png"
        title="Common Compliance Pitfalls and How to Avoid Them  "
        date="Mar 23, 2018"
      />
      </Link>
      <Link to="/culture">
      <SidebarPosts
        postImage="/post-image1@2x.png"
        title="The Role of Culture in Compliance: Building a Security-Conscious Organisation"
        date="Mar 23, 2018"
      />
      </Link>
      <Link to="/trends">
      <SidebarPosts
        postImage="/post-image2@2x.png"
        title="The Future of Compliance Technology: Trends to Watch"
        date="Mar 23, 2018"
      />
      </Link>
      <Link to="/e8Guide">
      <SidebarPosts
        postImage="/rectangle-11@2x.png"
        title={`Navigating the Essential Eight: A Comprehensive Guide to ASD & ACSC Guidelines`}
        date="Mar 23, 2018"
      />
      </Link>
    </PostsRoot>
  );
};

export default Posts;
