import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { Button, Icon } from "@mui/material";
import styled, { keyframes } from "styled-components";

import Navbar from "../components/navbar";
import Footer from '../components/footer';
import SIDEBAR from '../components/s-i-d-e-b-a-r';

import HeroInner from '../components/hero-inner';
import ALEiGNDrawer from "../components/a-l-ei-g-n-drawer";
import PortalDrawer from "../components/portal-drawer";

const LogoIcon = styled.img`
  align-self: stretch;
  width: 6.25rem;
  max-height: 100%;
  object-fit: cover;
`;
const Continlogo2a1Icon = styled.img`
  width: 4.875rem;
  position: relative;
  height: 2.375rem;
  overflow: hidden;
  flex-shrink: 0;
`;

const LinkOne = styled(Button)`
  position: relative;
`;

const NavLinkDropdown = styled(Button)`
  align-self: stretch;
`;

const ActionButton1 = styled(Button)`
  display: none;
`;

const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;

const animationSlideInTop = keyframes`
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  `;

const CanYouImagine = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.25rem;
  font-weight: 500;
`;
const WeKnowThis = styled.b`
  position: absolute;
  width: 100%;
  top: 36.47%;
  left: 0%;
  line-height: 120%;
  display: inline-block;
  font-family: var(--h2a);
`;
const QuoteIcon = styled.img`
  position: absolute;
  height: 22.35%;
  width: 9.55%;
  top: 0%;
  right: 90.45%;
  bottom: 77.65%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;
const Quote = styled.div`
  align-self: stretch;
  position: relative;
  height: 10.625rem;
  font-size: var(--h3a-size);
  color: var(--color-darkslategray-200);
`;
const InThisExploration = styled.p`
  margin-block-start: 0;
  margin-block-end: 7px;
  letter-spacing: 0.04em;
  line-height: 1.813rem;
`;
const LackOfA = styled.span`
  line-height: 2.25rem;
`;
const Pitfall = styled.span`
  font-weight: 500;
`;
const LackOfAHolisticApproach = styled.p`
  margin-block-start: 0;
  margin-block-end: 7px;
  font-size: var(--h3-size);
`;
const TreatingComplianceAs = styled.span`
  font-weight: 300;
`;
const PitfallTreatingComplianceA = styled.span`
  letter-spacing: 0.04em;
  line-height: 1.813rem;
`;
const PitfallTreatingComplianceA1 = styled.p`
  margin-block-start: 0;
  margin-block-end: 7px;
`;
const AvoidanceStrategy = styled.span`
  font-weight: 500;
`;
const Conclusion = styled.p`
  margin-block-start: 0;
  margin-block-end: 7px;
  font-size: var(--h3-size);
  line-height: 2.25rem;
  font-weight: 500;
`;
const NavigatingTheLandscape = styled.p`
  margin-block-start: 0;
  margin-block-end: 7px;
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
`;
const InOurUpcoming = styled.p`
  margin: 0;
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
`;
const DevelopedByTheContainer = styled.div`
  width: 46.938rem;
  position: relative;
  display: inline-block;
  font-size: var(--p1-size);
`;
const TagsAppMobile = styled.b`
  width: 18.775rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  text-transform: uppercase;
  display: inline-block;
  white-space: pre-wrap;
`;
const Content = styled.div`
  width: 46.938rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-51xl);
`;
const PopularPosts = styled.b`
  position: relative;
  line-height: 120%;
`;
const TitleChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0.188rem;
  flex-shrink: 0;
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--h3a-size);
`;
const PostImageIcon = styled.img`
  width: 5rem;
  position: relative;
  height: 5rem;
  object-fit: cover;
`;
const ForWomenOnly = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.25rem;
  font-weight: 500;
  white-space: pre-wrap;
`;
const Mar = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-small-normal-size);
  line-height: 1.5rem;
  font-weight: 200;
  color: var(--color-lightslategray);
  display: inline-block;
  height: 1.563rem;
  flex-shrink: 0;
`;
const PostContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
`;
const Post = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const Posts = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const Technology = styled.div`
  width: 17.688rem;
  position: relative;
  line-height: 2.25rem;
  font-weight: 500;
  display: inline-block;
`;
const Posts1 = styled.div`
  width: 14.375rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  line-height: 1.5rem;
  font-weight: 200;
  color: var(--color-lightslategray);
  display: inline-block;
  height: 1.563rem;
  flex-shrink: 0;
`;
const LineIcon = styled.img`
  width: 22rem;
  position: relative;
  height: 0.188rem;
`;
const Div = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
`;
const Category = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;

const BlogPost = styled.section`
  align-self: stretch;
  background-color: var(--dominant);
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-71xl) var(--padding-101xl);
  gap: var(--gap-81xl);
  text-align: left;
  font-size: var(--h3-size);
  color: var(--color-lightslategray);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const TrustedByThe = styled.div`
  width: 55.444rem;
  position: relative;
  display: inline-block;
`;
const BrandChild = styled.img`
  width: 8.881rem;
  position: relative;
  height: 0.188rem;
`;
const Compliancelogos1Icon = styled.img`
  width: 29.688rem;
  position: relative;
  height: 6.063rem;
  object-fit: cover;
  mix-blend-mode: darken;
`;
const Compliancelogos2Icon = styled.img`
  width: 30.313rem;
  position: relative;
  height: 6.063rem;
  object-fit: cover;
  mix-blend-mode: multiply;
`;
const Compliancelogos1Parent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
`;
const Brand = styled.div`
  width: 90rem;
  background-color: var(--primary-main);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-31xl) var(--padding-238xl);
  box-sizing: border-box;
  gap: var(--gap-10xl);
`;
const CalloutSwitzerlandSunsetIma = styled.img`
  align-self: stretch;
  width: 20.938rem;
  position: relative;
  border-radius: var(--br-5xs);
  max-height: 100%;
  object-fit: cover;
  display: none;
  @media screen and (max-width: 960px) {
    width: 100%!importanrt;
    height: auto !important;
  }
`;
const CalloutSwitzerlandSunsetIma1 = styled.img`
  align-self: stretch;
  height: 16.706rem;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 100%!importanrt;
    height: auto !important;
  }
`;
const CalloutSwitzerlandMountains = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 100% !important;
    height: auto !important;
  }
`;
const CalloutImagesColumn = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  @media screen and (max-width: 1200px) {
    width: 50% !important;
  }
  @media screen and (max-width: 960px) {
    width: 50% !important;
  }
`;
const CalloutImages = styled.div`
  width: 36.25rem;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  @media screen and (max-width: 420px) {
    width: 96% !important;
  }
`;
const CalloutImagesWrapper = styled.div`
  align-self: stretch;
  width: 37.5rem;
  background-color: var(--color-whitesmoke-300);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  min-width: 37.5rem;
`;
const FrameChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 15rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const DesignToCode = styled.div`
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
`;
const MinRead = styled.div`
  height: 1.125rem;
  position: relative;
  letter-spacing: -0.04em;
  display: inline-block;
`;
const MinReadWrapper = styled.div`
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-7xs);
  font-size: var(--text-small-normal-size);
  color: var(--color-dimgray);
`;
const DesignToCodeParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs);
`;
const LocofyaiTurn = styled.b`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-7xl);
  letter-spacing: 0.02em;
  line-height: 2.125rem;
  color: var(--studio-darkmode-exportbg-1a202e);
`;
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const GoFromFigma = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;
`;
const GoFromFigmaToCodeInMinimWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--studio-darkmode-exportbg-1a202e);
`;
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const FrameItem = styled.div`
  width: 24.794rem;
  position: relative;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
  height: 0.044rem;
`;
const AvatarIcon = styled.img`
  width: 2.5rem;
  position: relative;
  border-radius: 50%;
  height: 2.5rem;
  object-fit: cover;
`;
const AnnaRos = styled.div`
  width: 9.094rem;
  height: 1.313rem;
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  display: inline-block;
`;
const PostedJustNow = styled.div`
  width: 9.094rem;
  height: 1rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  color: var(--studio-darkmode-normalsolid-icons-96a2be);
  display: inline-block;
`;
const AuthorText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
`;
const ArticleAuthor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const LineParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-mid);
  color: var(--color-dimgray);
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-smi);
`;
const RectangleParent = styled.div`
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--dominant);
  border: 0.7px solid var(--studio-lightmode-midseparator-dee3eb);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const MinReadContainer = styled.div`
  border-radius: var(--br-9xs);
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-7xs);
  font-size: var(--text-small-normal-size);
  color: var(--color-dimgray);
`;
const DesignToCodeGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const LineDiv = styled.div`
  width: 18.919rem;
  position: relative;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
  height: 0.044rem;
`;
const LineGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-mid);
  color: var(--color-dimgray);
`;
const FrameChild1 = styled.div`
  width: 20.669rem;
  position: relative;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
  height: 0.044rem;
`;
const FrameParent1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-base);
`;
const RectangleParent1 = styled.div`
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--dominant);
  border: 0.7px solid var(--studio-lightmode-midseparator-dee3eb);
  box-sizing: border-box;
  height: 36.188rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const ContentSection = styled.div`
  align-self: stretch;
  background-color: var(--dominant);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xl);
  gap: var(--gap-21xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const ContentSectionWrapper = styled.div`
  flex: 1;
  background-color: var(--color-whitesmoke-300);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  min-width: 37.5rem;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Layouts = styled.div`
  width: 90rem;
  background-color: var(--dominant);
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--accent);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;


const PitfallsRoot = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-2xl);
  color: var(--dominant);
`;

const Pitfalls = () => {
  const [isALEiGNDrawerOpen, setALEiGNDrawerOpen] = useState(false);

  const openALEiGNDrawer = useCallback(() => {
    setALEiGNDrawerOpen(true);
  }, []);

  const closeALEiGNDrawer = useCallback(() => {
    setALEiGNDrawerOpen(false);
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <>
      <PitfallsRoot>
        <Navbar
        continLogo2a1="/continlogo2a-1.svg"
        accountSection={false}
        hamburgerMenu="/notification.svg"
        showHamburgerMenuIcon
        notificationBell="/notification1.svg"
        profilePicture="/top_avatar@2x.png"
        actionButton="Get in Touch"
        iconChevronRight="/icon--chevronright@2x.png"
        showActionButton={false}
        showActionButtonLink={false}
      />
			<HeroInner
				headerTitle={`Navigating the Essential Eight: A Comprehensive Guide to ASD & ACSC Guidelines`}
				tagDate='Compliance  -  Feb 10, 2024'
				headerImage='/kyoto-card-image@2x.png'
			/>
        <BlogPost>
          <Content>
            <CanYouImagine>{`Embarking on the path of compliance is a strategic imperative for organisations aiming to fortify their operations and uphold industry standards. `}</CanYouImagine>
            <Quote>
              <WeKnowThis>{`However, the journey is fraught with potential pitfalls that can undermine even the most meticulously crafted compliance strategies. `}</WeKnowThis>
              <QuoteIcon alt="" src="/quote1@2x.png" />
            </Quote>
            <DevelopedByTheContainer>
              <InThisExploration>
                In this exploration, we uncover common compliance pitfalls and
                arm you with the insights to sidestep them effectively. Let’s
                dive in.
              </InThisExploration>
              <LackOfAHolisticApproach>
                <Pitfall>
                  <LackOfA>1. Lack of a Holistic Approach:</LackOfA>
                </Pitfall>
              </LackOfAHolisticApproach>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <Pitfall>Pitfall:</Pitfall>
                  <TreatingComplianceAs>
                    {" "}
                    Treating compliance as a mere checklist rather than an
                    integral part of business operations.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <AvoidanceStrategy>Avoidance Strategy:</AvoidanceStrategy>
                  <TreatingComplianceAs>
                    {" "}
                    Integrate compliance into the fabric of your organisation.
                    Align it with your business goals, and consider the broader
                    impact on processes and stakeholders.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <LackOfAHolisticApproach>
                <Pitfall>
                  <LackOfA>2. Inadequate Training and Awareness:</LackOfA>
                </Pitfall>
              </LackOfAHolisticApproach>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <Pitfall>Pitfall:</Pitfall>
                  <TreatingComplianceAs>
                    {" "}
                    Assuming that compliance knowledge is implicit and doesn't
                    require continuous training.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <AvoidanceStrategy>Avoidance Strategy:</AvoidanceStrategy>
                  <TreatingComplianceAs>
                    {" "}
                    Prioritise ongoing training programs to keep your team
                    abreast of evolving regulations. Foster a culture where
                    compliance awareness is a shared responsibility.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <LackOfAHolisticApproach>
                <Pitfall>
                  <LackOfA>3. Insufficient Documentation:</LackOfA>
                </Pitfall>
              </LackOfAHolisticApproach>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <Pitfall>Pitfall:</Pitfall>
                  <TreatingComplianceAs>
                    {" "}
                    Neglecting thorough documentation of compliance processes
                    and activities.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <AvoidanceStrategy>Avoidance Strategy:</AvoidanceStrategy>
                  <TreatingComplianceAs>
                    {" "}
                    Establish a robust documentation system. Comprehensive
                    records not only demonstrate compliance but also serve as a
                    valuable resource for audits and assessments.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <LackOfAHolisticApproach>
                <Pitfall>
                  <LackOfA>4. Reactive rather than Proactive Approach:</LackOfA>
                </Pitfall>
              </LackOfAHolisticApproach>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <Pitfall>Pitfall:</Pitfall>
                  <TreatingComplianceAs>
                    {" "}
                    Waiting for audit time to address compliance issues instead
                    of proactively identifying and rectifying potential
                    problems.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <AvoidanceStrategy>{`Avoidance Strategy: `}</AvoidanceStrategy>
                  <TreatingComplianceAs>
                    Implement continuous monitoring and proactive compliance
                    measures. Address issues as they arise, reducing the risk of
                    non-compliance during audits.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <LackOfAHolisticApproach>
                <Pitfall>
                  <LackOfA>5. Overlooking Third-Party Risks:</LackOfA>
                </Pitfall>
              </LackOfAHolisticApproach>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <Pitfall>Pitfall:</Pitfall>
                  <TreatingComplianceAs>
                    {" "}
                    Focusing solely on internal processes and neglecting the
                    compliance risks associated with third-party vendors and
                    partners.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <AvoidanceStrategy>Avoidance Strategy:</AvoidanceStrategy>
                  <TreatingComplianceAs>
                    {" "}
                    Establish stringent vendor management practices. Regularly
                    assess and ensure the compliance of third parties that have
                    access to your systems or handle sensitive data.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <LackOfAHolisticApproach>
                <Pitfall>
                  <LackOfA>6. Failure to Adapt to Regulatory Changes:</LackOfA>
                </Pitfall>
              </LackOfAHolisticApproach>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <Pitfall>Pitfall:</Pitfall>
                  <TreatingComplianceAs>
                    {" "}
                    Operating under outdated compliance frameworks and failing
                    to adapt to evolving regulations.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <PitfallTreatingComplianceA1>
                <PitfallTreatingComplianceA>
                  <AvoidanceStrategy>Avoidance Strategy:</AvoidanceStrategy>
                  <TreatingComplianceAs>
                    {" "}
                    Stay informed about regulatory updates. Regularly review and
                    update your compliance protocols to align with the latest
                    industry standards.
                  </TreatingComplianceAs>
                </PitfallTreatingComplianceA>
              </PitfallTreatingComplianceA1>
              <Conclusion>Conclusion:</Conclusion>
              <NavigatingTheLandscape>
                Navigating the landscape of compliance requires a proactive
                stance, continuous diligence, and a commitment to learning from
                the common pitfalls that organisations often encounter. By
                anticipating these challenges and implementing effective
                avoidance strategies, you can ensure that your compliance
                journey is not just a task but a strategic advantage that
                propels your organisation toward enduring success.
              </NavigatingTheLandscape>
              <Conclusion>Next Steps:</Conclusion>
              <InOurUpcoming>
                In our upcoming series, we will delve deeper into each of these
                pitfalls, providing detailed insights and actionable steps to
                fortify your compliance strategy.
              </InOurUpcoming>
            </DevelopedByTheContainer>
            <TagsAppMobile>Tags: Compliance, E8, Story</TagsAppMobile>
          </Content>
          
          <SIDEBAR/>
        </BlogPost>
        <Brand>
          <TrustedByThe>
            Continuous compliance for mulitple frameworks
          </TrustedByThe>
          <BrandChild alt="" src="/line-3.svg" />
          <Compliancelogos1Parent>
            <Compliancelogos1Icon alt="" src="/compliancelogos-11@2x.png" />
            <Compliancelogos2Icon alt="" src="/compliancelogos-2@2x.png" />
          </Compliancelogos1Parent>
          <BrandChild alt="" src="/line-3.svg" />
        </Brand>
        <Layouts>
          <CalloutImagesWrapper>
            <CalloutImages>
              <CalloutSwitzerlandSunsetIma
                alt=""
                src="/callout-switzerland-sunset-image@2x.png"
              />
              <CalloutImagesColumn>
                <CalloutSwitzerlandSunsetIma1
                  alt=""
                  src="/callout-switzerland-sunset-image1@2x.png"
                />
                <CalloutSwitzerlandMountains
                  alt=""
                  src="/callout-switzerland-mountains-popup2@2x.png"
                />
              </CalloutImagesColumn>
              <CalloutImagesColumn>
                <CalloutSwitzerlandMountains
                  alt=""
                  src="/callout-switzerland-mountains-popup1@2x.png"
                />
                <CalloutSwitzerlandSunsetIma1
                  alt=""
                  src="/callout-switzerland-sunset-image2@2x.png"
                />
              </CalloutImagesColumn>
            </CalloutImages>
          </CalloutImagesWrapper>
          <ContentSectionWrapper>
            <ContentSection>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-478@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeParent>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadWrapper>
                          <MinRead>3 min read</MinRead>
                        </MinReadWrapper>
                      </DesignToCodeParent>
                      <LocofyaiTurn>
                        Locofy.ai – Turn Figma to Code!
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Go from Figma to code in minimum amount of time using
                        Locofy. Take your..
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineParent>
                    <FrameItem />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar@2x.png" />
                      <AuthorText>
                        <AnnaRos>Anna Rosé</AnnaRos>
                        <PostedJustNow>Posted just now</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineParent>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4781@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>Figma for Developers</LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Developers often believe they will be off to a flying
                        start if they skip the designing process and jump
                        directly to code.
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <LineDiv />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar1@2x.png" />
                      <AuthorText>
                        <AnnaRos>Justin Chen</AnnaRos>
                        <PostedJustNow>Posted on Sep 6, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent1>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent1>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent1>
              </RectangleParent1>
            </ContentSection>
          </ContentSectionWrapper>
          <ContentSectionWrapper>
            <ContentSection>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-478@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeParent>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadWrapper>
                          <MinRead>3 min read</MinRead>
                        </MinReadWrapper>
                      </DesignToCodeParent>
                      <LocofyaiTurn>
                        Locofy.ai – Turn Figma to Code!
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Go from Figma to code in minimum amount of time using
                        Locofy. Take your..
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineParent>
                    <FrameItem />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar@2x.png" />
                      <AuthorText>
                        <AnnaRos>Anna Rosé</AnnaRos>
                        <PostedJustNow>Posted just now</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineParent>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4781@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>Figma for Developers</LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Developers often believe they will be off to a flying
                        start if they skip the designing process and jump
                        directly to code.
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <LineDiv />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar1@2x.png" />
                      <AuthorText>
                        <AnnaRos>Justin Chen</AnnaRos>
                        <PostedJustNow>Posted on Sep 6, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent1>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent1>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent1>
              </RectangleParent1>
            </ContentSection>
          </ContentSectionWrapper>
        </Layouts>
        
        <Footer/>
      </PitfallsRoot>
      {isALEiGNDrawerOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeALEiGNDrawer}
        >
          <ALEiGNDrawer onClose={closeALEiGNDrawer} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Pitfalls;
