import * as React from "react";
import styled from "styled-components";

const Category1 = styled.b`
  position: relative;
  line-height: 120%;
`;
const TitleChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0.188rem;
  flex-shrink: 0;
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--h3a-size);
  font-family: var(--h3a);
`;
const Technology = styled.div`
  width: 17.688rem;
  position: relative;
  line-height: 2.25rem;
  display: inline-block;
`;
const Posts = styled.div`
  width: 14.375rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  line-height: 1.5rem;
  font-weight: 200;
  color: var(--color-lightslategray);
  display: inline-block;
  height: 1.563rem;
  flex-shrink: 0;
`;
const LineIcon = styled.img`
  width: 22rem;
  position: relative;
  height: 0.188rem;
`;
const Div = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
`;
const CategoryRoot = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--h3-size);
  color: var(--color-darkslategray-200);
  font-family: var(--p2);
`;

const Category = () => {
  return (
    <CategoryRoot>
      <Title>
        <Category1>Category</Category1>
        <TitleChild alt="" src="/line-61.svg" />
      </Title>
      <Div>
        <Technology>Technology</Technology>
        <Posts>3 posts</Posts>
        <LineIcon alt="" src="/line.svg" />
      </Div>
      <Div>
        <Technology>Application</Technology>
        <Posts>3 posts</Posts>
        <LineIcon alt="" src="/line.svg" />
      </Div>
      <Div>
        <Technology>Lifestyle</Technology>
        <Posts>3 posts</Posts>
        <LineIcon alt="" src="/line.svg" />
      </Div>
      <Div>
        <Technology>Education</Technology>
        <Posts>3 posts</Posts>
        <LineIcon alt="" src="/line.svg" />
      </Div>
    </CategoryRoot>
  );
};

export default Category;
