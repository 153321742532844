import * as React from "react";
import styled from "styled-components";

const PostImageIcon = styled.img`
  width: 5rem;
  position: relative;
  height: 5rem;
  object-fit: cover;
`;
const ForWomenOnly = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.25rem;
  white-space: pre-wrap;
`;
const Mar = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-small-normal-size);
  line-height: 1.5rem;
  font-weight: 200;
  color: var(--color-lightslategray);
  display: inline-block;
  height: 1.563rem;
  flex-shrink: 0;
`;
const PostContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
`;
const PostRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--h3-size);
  color: var(--color-darkslategray-200);
  font-family: var(--p2);
`;

const SidebarPosts = ({ postImage, title, date }) => {
  return (
    <PostRoot>
      <PostImageIcon alt="" src={postImage} />
      <PostContent>
        <ForWomenOnly>{title}</ForWomenOnly>
        <Mar>{date}</Mar>
      </PostContent>
    </PostRoot>
  );
};

export default SidebarPosts;
