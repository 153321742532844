import * as React from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Continlogo2a1Icon = styled.img`
  width: 61.144rem;
  position: absolute;
  margin: 0 !important;
  top: -8.956rem;
  left: -11.506rem;
  height: 56.294rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 0;
`;
const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;
const TheActiveAlignment = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.25rem;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const NavigatingTheEssential = styled.b`
  align-self: stretch;
  position: relative;
  font-size: var(--h1a-size);
  line-height: 120%;
  font-family: var(--h2a);
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const ComplianceFeb = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  white-space: pre-wrap;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const HeroText = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const HeroContent = styled.div`
  width: 41.313rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem 0rem var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0;
  z-index: 1;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInLeft};
  }
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: center;
  }
`;
const animationSlideInTop = keyframes`
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  `;
const HeroSectionsChild = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 2;
  &.animate {
    animation: 0.5s ease 0s 1 normal forwards ${animationSlideInTop};
  }
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const HeroSectionsRoot = styled.section`
  align-self: stretch;
  background-color: var(--dominant);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-81xl);
  position: relative;
  gap: var(--gap-41xl);
  text-align: left;
  font-size: var(--h3-size);
  color: var(--dark-gray);
  font-family: var(--p2);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    padding: var(--padding-31xl);
    box-sizing: border-box;
  }
`;

const HeroInner = ({ headerTitle, tagDate = "tag - date", headerImage }) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <HeroSectionsRoot>
      <Continlogo2a1Icon alt="" src='/continlogo2a-12@2x.png' />
      <HeroContent data-animate-on-scroll>
        <HeroText>
          <TheActiveAlignment>The Active Alignment Journal</TheActiveAlignment>
          <NavigatingTheEssential>{headerTitle}</NavigatingTheEssential>
          <ComplianceFeb>{tagDate}</ComplianceFeb>
        </HeroText>
      </HeroContent>
      <HeroSectionsChild
        alt=""
        src={headerImage}
        data-animate-on-scroll
      />
    </HeroSectionsRoot>
  );
};

export default HeroInner;
